<template>
    <card-list>
        <subheader-list-item icon="fa-align-left" title="Add-On information"/>
        <container-list-item padded>
            <div class="columns">
                <div class="column">
                    <b-field label="Type" label-position="inside">
                        <b-select v-model="addon.type" expanded>
                            <option value="departure">Departure</option>
                            <option value="extensions">Extension</option>
                            <option value="airfare">Airfare</option>
                            <option value="aircredit">Airfare Credit</option>
                            <option value="accomodations">Accommodations</option>
                            <option value="tennis">Tennis</option>
                            <option value="other">Other</option>
                        </b-select>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Status" label-position="inside">
                        <b-select v-model="addon.status" expanded>
                            <option value="open">Open</option>
                            <option value="closed">Closed</option>
                            <option value="hidden">Hidden</option>
                        </b-select>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Title" label-position="inside">
                        <b-input v-model="addon.title"></b-input>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Single Cost" label-position="inside">
                        <b-input v-model="addon.single_cost"></b-input>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Double Cost" label-position="inside">
                        <b-input v-model="addon.double_cost"></b-input>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Quantity" label-position="inside">
                        <b-input v-model="addon.quantity"></b-input>
                    </b-field>
                </div>
            </div>
            <div class="columns mb-3">
                <div class="column">
                    <b-field label="Description" label-position="inside">
                        <b-input type="textarea" v-model="addon.summary"/>
                    </b-field>
                </div>
            </div>
        </container-list-item>

        <subheader-list-item icon="fa-tasks" title="Task"/>
        <container-list-item padded>
            <div class="columns">
                <div class="column">
                    <b-field label="Status" label-position="inside">
                        <b-select expanded v-model="addon.task_status">
                            <option value="planned">Planned</option>
                            <option value="booked">Booked</option>
                            <option value="confirmed">Confirmed</option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Payment" label-position="inside">
                        <b-select expanded v-model="addon.task_payment">
                            <option value="unpaid">Unpaid</option>
                            <option value="deposit">Deposit</option>
                            <option value="paid">Paid</option>
                            <option value="na">N/A</option>
                        </b-select>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Assignment" label-position="inside">
                        <b-select expanded v-model="addon.task_assigned_id">
                            <option :value="a.id" v-for="a in assignees">{{a.first_name}} {{a.last_name}}</option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column is-4">
                    <b-switch :native-value="1" :true-value="1" :false-value="0" v-model="addon.task_complete">Complete</b-switch>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Notes" label-position="inside">
                        <b-input type="textarea" v-model="addon.task_notes"/>
                    </b-field>
                </div>
            </div>
        </container-list-item>
    </card-list>
</template>

<script>
    import CardList from "@/TIER/components/CardList";
    import SubheaderListItem from "@/TIER/components/SubheaderListItem";
    import ContainerListItem from "@/TIER/components/ContainerListItem";
    import {mapGetters} from "vuex";

    export default {
        name: 'AddonForm',
        props: {
            addon: {
                type: Object,
                required: true
            }
        },
        components: {ContainerListItem, SubheaderListItem, CardList},
        computed: {
            ...mapGetters(['assignees'])
        }
    };

</script>

<style scoped>

</style>
