<template>
    <div>
        <card-header title="New Add-On" icon="fa-plus"/>

        <card-body>
            <addon-form :addon="newAddon"/>
        </card-body>

        <card-footer class="pt-3 pb-3 has-text-right">
            <b-button type="is-primary" size="is-small" :disabled="!newAddon.title || !newAddon.type" @click="submit"><i class="fas fa-plus mr-3"></i>New Add-On</b-button>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from '@/TIER/components/CardHeader';
    import CardContainer from '@/TIER/components/CardContainer';

    import {client as http} from '@/http_client';
    import CardFooter from "@/TIER/components/CardFooter";
    import CardList from "@/TIER/components/CardList";
    import SubheaderListItem from "@/TIER/components/SubheaderListItem";

    import CardBody from "@/TIER/components/CardBody";
    import ContainerListItem from "@/TIER/components/ContainerListItem";
    import AddonForm from "./AddonForm";

    export default {
        props: ['card', 'props'],
        components: {AddonForm, ContainerListItem, CardBody, SubheaderListItem, CardList, CardFooter, CardContainer, CardHeader},
        data: function () {
            return {
                newAddon: {
                    title: '',
                    status: 'open',
                    single_cost: 0,
                    double_cost: 0
                }
            };
        },
        methods: {
            submit() {
                this.$emit('loading', true);
                http.post('/api/addons/', {trip_id: this.props.tripId, ...this.newAddon}, {force: true}).then(response => {
                    this.$reloadCard('trip-addon', {addonId: this.props.addonId});
                    this.$reloadCard('trip-addons', {tripId: this.props.tripId});
                    this.$reloadCard('addons-list', {tripId: this.props.tripId});
                    this.$reloadCard('trip', {tripId: this.props.tripId});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {

                });
            }
        },
        mounted() {
            this.$saveAlert(true, 'Are you done creating this new Add-On? Unsaved changes will be lost.');
        }
    };
</script>
